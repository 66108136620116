import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default ({ article }) => (
  <div className={article.displayPostion}>
    
    <div className={article.displayImagePostion}>
        <Img className="classes_preview_img" alt="test" fluid={article.heroImage.fluid} />
    </div>
    <div>
        <h3>
            {article.title}
            {/* <Link to={`/${article.slug}`}>{article.title}</Link> */}
        </h3>
        
        
        <p
        dangerouslySetInnerHTML={{
            __html: article.body.body,
        }}
        />
        <hr style={{border: '1px solid #73a9ce'}}/>  

    </div>
    
    
  </div>
)
