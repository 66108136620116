import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/SEO'
import Layout from "../components/Layout"
import ClassDetail from '../components/classes-detail'

class ClassesPageIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    var all_seo = this.props.data.allContentfulPageSeo.edges;
    var all_classes = this.props.data.allContentfulEventType.edges;
    var pagename = 'classes'
    
    var classes = []
    var seo = []
    for (var i = 0; i < all_classes.length; i++) {      
      var id_exists = false;
      for (var j =0; j < classes.length; j++) {
        if (all_classes[i].node.contentId == classes[j].node.contentId) { id_exists = true;}
      }
      if (id_exists == false & classes.length < 6) { classes.splice(all_classes[i].node.displayRank, 0, all_classes[i])}
    }
    for (var i = 0; i < all_seo.length; i++) {
      var id_exists = false;
      for (var j =0; j < seo.length; j++) {
        if (all_seo[i].node.contentId == seo[j].node.contentId) { id_exists = true;}
      }      
      if (id_exists == false & all_seo[i].node.pageName == pagename) { seo.push(all_seo[i])}
    
    }
    console.log(classes)

    return (
      <Layout location={this.props.location} >
        <SEO pageSEOdata={seo}/>
        <div style={{ background: '#fff' }}>          
          <div className='general_header classespage_header'style={{color:'#ffffff'}} />          
          <div className="page_wrapper">
            <h1 className="section-headline">Classes</h1>
              <ul className="classesdetail-list">
                  {classes.map(function ({ node }) {
                      return (
                          <li key={node.slug}>
                              <ClassDetail article={node} />
                          </li>
                          )
                      })}
              </ul>
          </div>
        </div>

      </Layout>
    )
  }
}

export default ClassesPageIndex

export const pageQuery = graphql`
  query ClassesPageIndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulPageSeo(filter: {siteName: {eq: "alliecrombieyoga"}}) {
      edges {
        node {
          contentId
          description
          googleSiteVerification
          keywords {
            keywords
          }
          ogdescription
          oglocale
          ogtitle
          ogtype
          ogurl
          pageName
          siteName
          title
          twittercard
          twittercreator
          twitterdescription
          twittertitle
        }
      }
    }
    allContentfulEventType(filter: {site: {eq: "alliecrombieyoga"}}) {
      edges {
        node {
          id
          title
          slug
          description {
            description
          }
          heroImage {
            fluid{
              base64              
              srcWebp
              srcSetWebp
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          contentId
          body {
            body
          }
          displayImagePostion
          displayRank
          displayPostion
        }
      }
    }   
  }
`
